export const EXPERIMENTS = {
  FIT_TO_CONTENT_HEIGHT: 'specs.membership.FitToContentHeight',
  SHOW_ADD_COUPON_LABEL: 'specs.membership.ShowAddCouponLabel',
  PLAN_LIMIT: 'specs.membership.PlanLimit',
  USE_BLOCKS_SINGLE_PLAN_WIDGET: 'specs.membership.UseBlocksSinglePlanWidget',
  USE_BLOCKS_PACKAGE_PICKER_PAGE: 'specs.membership.UseBlocksPackagePickerPage',
  FULL_DISCOUNT_UPDATED_CHECKOUT: 'specs.membership.FullDiscountUpdatedCheckout',
  DAYFUL_SUPPORT: 'specs.membership.DayfulSupport',
  LOADERS: 'specs.membership.Loaders',
  ALERT_MODALS: 'specs.membership.AlertModals',
  GUEST_CHECKOUT: 'specs.membership.GuestCheckout',
  TERMS_AND_CONDITIONS_CHECKBOX: 'specs.membership.TermsAndConditionsCheckboxLiveSite',
  USE_WARMUP_DATA: 'specs.membership.UseWarmupData',
  FORCE_MEMBERS_AREA_INSTALL: 'specs.membership.ForceMemberAreaInstall',
  SCROLL_INTO_CHECKOUT: 'specs.membership.ScrollIntoCheckout',
  ADDITIONAL_INFO_IN_CHECKOUT: 'specs.membership.AdditionalInfoInCheckout',
  DO_NOT_COLLAPSE_PAYMENTS_WIDGET: 'specs.membership.DoNotCollapsePaymentsWidget',
};
